/* Default/Mobile styling */
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  background: #fff;
}

.btn_custom {
  background: var(--primary-red);
  border: none;
  border-radius: 100vw;
  color: #fff;
  cursor: pointer;
  font-family: 'Gotham';
  font-size: 5vw;
  font-style: italic;
  font-weight: 500;
  padding: 3vw 8vw;
  text-transform: uppercase;
  display: block;
}

/* Desktop styling */
@media only screen and (min-width: 900px) {

  .btn_custom {
    font-size: 1.5vw;
    padding: 1vw 4vw;
  }

}