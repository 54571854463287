/* Webcam */
.webcam_btn_cont {
  position: absolute;
  top: 15%;
  left: 12%;
  z-index: 12;
}

.webcam_btn_title {
  color: var(--primary-blue);
  font-family: 'Gotham';
  font-size: 6vw;
  font-weight: 700;
  margin-bottom: 1vw;
  text-transform: uppercase;
}

.webcam_btn_cont button {
  display: inline;
}

/* .webcam_container {
  overflow-y: hidden;
  background: #fff;
} */

#man-clip-path path {
  transform-origin: 0.5px 0.56px;
  /* transform: scaleX(1.05) rotate(180deg);
  transform: scaleX(1) rotate(0); */
}

.webcam,
.webcam_capture {
  clip-path: none;
  clip-path: url(#man-clip-path);
  -webkit-clip-path: url(#man-clip-path);
  margin-left: 2vw;
  margin-top: 0vw;
  position: relative;
  width: 100vw;
  z-index: 1;
}

.webcam_lab_coat {
  width: 120vw;
  position: absolute;
  left: -10vw;
  bottom: 0;
  z-index: 1;
}

@media only screen and (min-width: 900px) {

  .webcam_btn_title {
    font-size: 2vw;
    width: 25vw;
  }

  /* .webcam_container {
    height: 50vw;
  } */

  #man-clip-path path {
    transform-origin: 0.5px 0;
    /* transform: scaleX(0.62) scaleY(1.08);
    transform: scaleX(0.7) scaleY(1.2); */
  }

  .webcam,
  .webcam_capture {
    clip-path: url(#man-clip-path);
    -webkit-clip-path: url(#man-clip-path);
    /* clip-path: none; */
    width: 55vw;
    margin-left: 23.3vw;
    /* margin-top: 8.5vw;
    margin-top: 5vw; */
  }

  .webcam_lab_coat {
    width: 50vw;
    left: 25vw;
  }

}