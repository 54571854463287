.splash_coat_screen {
  color: var(--primary-blue);
  font-family: 'Gotham';
  padding: 15vw 0;
  text-transform: uppercase;
}

.splash_coat_title {
  font-size: 7vw;
  font-weight: 700;
  line-height: 7vw;
  margin-left: 8vw;
  margin-bottom: 4vw;
}

.splash_coat_logo_cont {
  display: flex;
  align-items: start;
}

.splash_coat_logo {
  margin-left: 8vw;
  margin-right: 3vw;
  width: 50vw;
}

.splash_coat_subtitle {
  font-size: 9vw;
  /* font-style: italic; */
  font-weight: 700;
  line-height: 6vw;
}

.splash_coat_screen .btn_custom {
  margin-left: 8vw;
  margin-top: 8vw;
}

.splash_coat_vector {
  margin-top: -10vw;
  width: 95vw;
  position: relative;
  z-index: -1;
}

.splash_coat {
  margin-top: 10vw;
  width: 100vw;
}

.splash_coat_logo_small {
  position: absolute;
  width: 15vw;
  top: 145vw;
  left: 30vw;
}

@media only screen and (min-width: 900px) {
  .splash_coat_screen {
    padding: 5vw 0;
  }

  .splash_coat_title {
    font-size: 3vw;
    line-height: 3vw;
    margin-left: 5vw;
    margin-bottom: 1.5vw;
  }

  .splash_coat_logo {
    margin-left: 5vw;
    margin-right: 1vw;
    width: 22vw;
  }

  .splash_coat_subtitle {
    font-size: 4vw;
    line-height: 3vw;
  }

  .splash_coat_screen .btn_custom {
    margin-left: 8vw;
    margin-top: 8vw;
  }

  .splash_coat_vector {
    margin-top: -6vw;
    width: 45vw;
  }

  .splash_coat_screen .btn_custom {
    margin-left: 5vw;
    margin-top: 4vw;
  }

  .splash_coat {
    width: 40vw;
    position: absolute;
    top: 0;
    right: 5vw;
  }

  .splash_coat_logo_small {
    top: 25vw;
    left: 67vw;
    width: 6vw;
  }
}