.form_custom {
  font-family: 'Gotham';
  padding: 15vw 0;
}

.form_main {
  padding: 0 8vw;
}

.form_title {
  color: var(--primary-blue);
  font-size: 7vw;
  font-weight: 700;
  line-height: 7vw;
  margin-left: 8vw;
  margin-bottom: 10vw;
  text-transform: uppercase;
}

.form_main label {
  color: #222222;
  font-weight: 300;
  font-size: 4vw;
}

.form_main label span {
  color: #f00;
}

.form_main input {
  width: calc(100% - 8vw);
  background: #f6f6f6;
  border: 0;
  border-radius: 1vw;
  outline: none;
  padding: 4vw;
  font-size: 4vw;
  margin: 2vw 0 4vw 0;
}

.form_main input[type="radio"] {
  width: unset;
  margin-top: 4vw;
  margin-right: 1vw;
}

.radio_label {
  margin-right: 4vw;
}

.form_vector {
  margin-top: -15vw;
  width: 95vw;
  position: relative;
  z-index: -1;
}

.form_main .btn_custom {
  margin-top: 8vw;
}

@media only screen and (min-width: 900px) {

  .form_custom {
    padding: 5vw 0;
    height: 100%;
    overflow-y: hidden;
  }

  .form_title {
    font-size: 3vw;
    line-height: 3vw;
    margin-left: 5vw;
    margin-bottom: 3vw;
  }

  .form_main {
    padding: 0 5vw;
  }

  .form_main label {
    font-size: 1vw;
  }

  .form_main input {
    width: calc(100% - 2vw);
    border-radius: 0.3vw;
    padding: 0.5vw 1vw;
    font-size: 1vw;
    margin: 0.5vw 0 1.5vw 0;
  }

  .form_main input[type="radio"] {
    margin-top: 1vw;
    margin-right: 0.5vw;
  }

  .radio_label {
    margin-right: 2vw;
  }

  .form_main .btn_custom {
    margin-top: 1.2vw;
    font-size: 1vw;
    padding: 0.8vw 3vw;
  }

  .form_vector {
    margin-top: -4vw;
    margin-bottom: 8vw;
    width: 30vw;
  }

}