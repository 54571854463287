body {
  margin: 0;
  --font-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Gotham', var(--font-fallback) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* GothamRounded */
@font-face {
  font-family: 'Gotham';
  src: url('./components/fonts/GothamRounded-Light.otf') format('opentype');
  font-weight: 100 200;
}

@font-face {
  font-family: 'Gotham';
  src: url('./components/fonts/GothamRounded-Book.otf') format('opentype');
  font-weight: 300 400;
}

@font-face {
  font-family: 'Gotham';
  src: url('./components/fonts/GothamRounded-Medium.otf') format('opentype');
  font-weight: 500 600;
}

@font-face {
  font-family: 'Gotham';
  src: url('./components/fonts/GothamRounded-Bold.otf') format('opentype');
  font-weight: 700 900;
}

:root {
  --primary-blue: #002172;
  --primary-red: #EA0029;
}